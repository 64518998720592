<template>
  <ServiceLayout>
    <div class="md-service-1">
      <!-- 广告图 -->
      <Banner :list="bannerList" height="4.92rem" v-ani.fade-up />
      <!-- 流程 -->
      <ServiceProcess :info="info" v-ani.fade-up />
      <!-- 提供服务 -->
      <ServiceList title='提供服务' :list='list' />
      <!-- 合作伙伴 -->
      <ServicePartner />
    </div>
  </ServiceLayout>
</template>

<script>
import { IndexBannerApi } from "@/request/api/user";
export default {
  created() {
    // 轮播图
    IndexBannerApi({ from: 425 }).then(({ status, data }) => {
      if (status == 200) {
        this.bannerList = data;
      }
    })
  },
  data() {
    return {
      // 轮播图列表
      bannerList: [
        // {
        //   id: 1,
        //   link: '',
        //   btn: false,
        //   target: '',
        //   img: require('@/assets/images/svc7_0.jpg'),
        //   title: '材料送检',
        //   intro: '送检材料质量'
        // },
      ],
      info: {
        title: '材料送检',
        desc: '材料送检将一些物质或成品送交给检测机构进行检验、检测的过程，确保材料符合规定的质量标准和技术要求。在工程施工过程中，需要使用各种材料，如砖、钢筋、混凝土等材料需要经过专业机构的检测认证后才能使用和进行后续施工。',
        list: [
          { id: 1, title: '选择资历<br>检测机构' },
          { id: 2, title: '填写送检单' },
          { id: 3, title: '提交样品' },
          { id: 4, title: '等待检测<br>结累' },
          { id: 5, title: '获取检测<br>报告' },
        ]
      },
      list: [
        {
          id: 1,
          img: require('@/assets/images/svc7_1.jpg'),
          tit: '材料取样',
          desc: '根据相关规范和标准，对各种工程材料进行取样,确保样品的代表性和准确性。'
        }, {
          id: 2,
          img: require('@/assets/images/svc7_2.jpg'),
          tit: '检测服务',
          desc: '具有资质和能力的检测机构合作,对样品进行全面的检测和分析。'
        }, {
          id: 3,
          img: require('@/assets/images/svc7_3.jpg'),
          tit: '先进设备',
          desc: '先进的检测设备和仪器,确保检测结果的准确性和可靠性。'
        }, {
          id: 4,
          img: require('@/assets/images/svc7_4.jpg'),
          tit: '快速响应',
          desc: '承诺在规定的时间内完成检测并出具报告。'
        },
      ]
    };
  },
};
</script>

<style lang="scss" scoped></style>